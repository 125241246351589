import React from 'react';
//images
import Logo from '../assets/logo.svg';
// files
import Cv from '../assets/CV - François Loan.pdf';

const Header = () => {
  return (
    <header className='py-8'>
      <div className='container mx-auto'>
        <div className='flex justify-between items-center'>
          {/* Logo */}
          <img src={Logo} alt='' />
          {/* Button */}
          <a href={Cv} target='_blank' rel='noreferrer'><button className='btn btn-sm'>Mon CV</button></a>
        </div>
      </div>
    </header>
  );
};

export default Header;
