import React from 'react';
// countup
import CountUp from 'react-countup';
// intersection observer hook
import { useInView } from 'react-intersection-observer';
// motion
import { motion } from 'framer-motion'
// variant
import { fadeIn } from '../variants';
// link
import { Link } from 'react-scroll';

const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  return (
    <section className='section' id='about' ref={ref}>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0  h-screen'>
          {/*
          <motion.div 
          variants={fadeIn('right', 0.3)}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3 }}
          className='flex-1 bg-about bg-contain bg-no-repeat h-[640px] mix-blend-lighten bg-top'
          ></motion.div>
          */}
          {/* text */}
          <motion.div
          variants={fadeIn('left', 0.5)}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3 }}
          className='flex-1'>
            <h2 className='h2 text-accent'>A propos de moi.</h2>
            <h3 className='h3 mb-4'>
              Je suis un étudiant en BTS SIO
            </h3>
            <p className='mb-6'>
              Je m'appelle Loan François, j’ai 20 ans et je suis actuellement diplomé d'un BTS SIO (Services Informatiques aux Organisations). J'ai pour objectif de réaliser un bachelor ainsi que deux ans de Master en développement logiciel (.NET). Je suis un passionné par le développement logiciel, web et mobile. Je suis également un grand fan de la technologie et de l’informatique en général ainsi que des jeux vidéo.
            </p>
            {/* stats */}
            <div className='flex gap-x-6 lg:gap-x-10 mb-12'>
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  { inView ? <CountUp start={0} end={20} duration={3} /> : null }
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Ans <br/>
                </div>
              </div>
              
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  { inView ? <CountUp start={0} end={10} duration={3} /> : null }
                  +
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Projets <br/>
                  réalisés
                </div>
              </div>

              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  { inView ? <CountUp start={0} end={3} duration={3} /> : null }
                  
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Ans <br/>
                  d'études
                </div>
              </div>

            </div>
            <div className='flex gap-x-8 items-center'>
              <Link to='contact' smooth={true}><button className='btn btn-lg'>Contactez moi</button></Link>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
