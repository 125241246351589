import React from 'react';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';
// hook-form
import { useForm } from 'react-hook-form';
// emailjs
import emailjs from 'emailjs-com';

const Contact = () => {
  const { handleSubmit, register, formState : {errors} } = useForm()
  
  async function onSubmit(data) {
    const serviceId = 'service_ey4fk49';
    const templateId = 'template_hfquews';
    const userId = 'daCZrB6BhWpHaNA6P'; // Replace with your user ID

    try {
      const response = await emailjs.send(serviceId, templateId, data, userId);
      console.log('E-mail sent successfully!', response);
      document.getElementById("frm").reset();
      document.getElementById("sended").style.display = "block";
    } catch (error) {
      console.error('Error sending e-mail:', error);
    }
  };

  return (
    <section className='py-16 lg:section' id='contact'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          {/* text */}
          <motion.div
          variants={fadeIn('right', 0.3)}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3 }}
          className='flex-1 flex justify-start items-center'>
            <div>
              <h4 className='text-xl uppercase text-accent font-medium mb-2 tracking-wide'>
                Entrer en contact
              </h4>
              <h2 className='text-[45px] lg:text-[90px] leading-none mb-5'>
                Discutons <br/> ensemble !
              </h2>
              <a href='mailto:pro.loanfrancois@gmail.com'><span className='mb-12 text-white/70'>pro.loanfrancois@gmail.com</span></a><br/>
              <span className='text-white/70'>07 77 76 09 35</span>
            </div>
          </motion.div>
          {/* form */}
          <motion.form 
          variants={fadeIn('left', 0.3)}
          id='frm'
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3 }}
          onSubmit={handleSubmit(onSubmit)}
          className='flex-1 border rounded-2xl flex flex-col gap-y-6 pb-24 p-6 items-start'>
            <input
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
              type='text'
              placeholder='Nom'
              {...register('name', {required: true, minLength: 3})}
            />
            {errors.name && <span className='text-red-500 mt-[-20px] mb-[-20px]'>Ce champ est requis</span>}
            <input
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
              type='text'
              placeholder='Email'
              {...register('email', {required: true})}
            />
            {errors.email && <span className='text-red-500 mt-[-20px] mb-[-20px]'>Ce champ est requis</span>}
            <textarea 
              className='bg-transparent border-b py-12 outline-none w-full placeholder:text-white focus:border-accent transition-all resize-none'
              placeholder='Message'
              {...register('message', {required: true})}
            ></textarea>
            {errors.message && <span className='text-red-500 mt-[-20px] mb-[-20px]'>Ce champ est requis</span>}
            <button className='btn btn-lg' type='submit'>Envoyer un message</button>
            <span id='sended' className='text-green-500 mt-[-20px] mb-[-20px] hidden' >Message envoyé</span>
          </motion.form> 
        </div>
      </div>
    </section>
  );
};

export default Contact;