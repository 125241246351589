import React from 'react';
// icon
import { BsArrowUpRight } from 'react-icons/bs';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';
// files
import Pix from '../assets/attestation-pix-20230228.pdf';
import Git from '../assets/git.pdf';
import Digital from '../assets/digital.pdf';

// services data
const services = [
  {
    name: 'PIX - 509 points',
    description: 'Outil d\'évaluation de compétences numériques.',
    link: 'Plus d\'infos',
    code: 'pix',
  },
  {
    name: 'Gérez du code avec Git et GitHub',
    description: 'Certification OpenClassrooms sur Git et GitHub.',
    link: 'Plus d\'infos',
    code: 'git',
  },
  {
    name: 'Gérez un projet digital avec une méthodologie en cascade',
    description: 'Certification OpenClassrooms sur la gestion de projet en cascade.',
    link: 'Plus d\'infos',
    code: 'digital',
  },
]

const Services = () => {
  return (
    <section className='section' id='services'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          {/* text & image */}
          <motion.div
          variants={fadeIn('right', 0.3)}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3 }}
          className='flex-1'>
            <h2 className='h2 text-accent mb-6'>Mes Certifications.</h2>
            <h3 className='h3 max-w-[455px] mb-16'>
              Durant ma formation, j'ai pu obtenir plusieurs certifications.
            </h3>
          </motion.div>
          {/* services */}
          <motion.div 
          variants={fadeIn('left', 0.5)}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3 }}
          className='flex-1'>
            {/* services list */}
            <div>
              {services.map((service, index) => {
                // destructure service
                const { name, description, link, code } = service;
                var pdfLink;
                if (code == 'pix') {
                  pdfLink = Pix;
                } else if (code == 'git') {
                  pdfLink = Git;
                } else if (code == 'digital') {
                  pdfLink = Digital;
                }
                return (
                <div className='border-b border-white/20 h-[146px] mb-[38px] flex' key={index}>
                  <div className='max-w-[476px]'>
                    <h4 className='text-[20px] tracking-wider font-primary font-semibold mb-6'>
                      {name}
                    </h4>
                    <p className='font-secondary leading-tight mb-5'>
                      {description}
                    </p>
                  </div>
                  <div className='flex flex-col flex-1 items-end'>
                    <a href={pdfLink} target="_blank" className='btn w-9 h-9 mb-[42px] flex justify-center items-center'>
                      <BsArrowUpRight />
                    </a>
                    <span className='text-gradient text-sm'>{link}</span>
                  </div>
                </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
